import React, { useEffect, useState } from 'react'
import PropType from 'prop-types'
import { withPreviewResolver } from 'gatsby-source-prismic'
import { linkResolver } from '../prismic/utils/linkResolver'

/**
 * Prismic Previews
 *
 * @link https://github.com/angeloashmore/gatsby-source-prismic/blob/master/docs/previews-guide.md
 */

const PreviewPage = ({ isPreview }) => {
  const [previewStatus, setPreviewStatus] = useState('Loading preview...')

  useEffect(() => {
    if (isPreview === false) {
      setPreviewStatus('Preview Data is invalid')
    }
  }, [isPreview])

  return (
    <div
      style={{
        position: 'fixed',
        top: '10px',
        left: '55px',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        color: '#ffffff',
        zIndex: 9999,
        padding: '0.5em 0.75em',
        fontSize: '24px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        borderRadius: '6px',
      }}
    >
      {previewStatus}
    </div>
  )
}

PreviewPage.defaultProps = {
  isPreview: true,
}

PreviewPage.propTypes = {
  isPreview: PropType.bool,
}

export default withPreviewResolver(PreviewPage, {
  repositoryName: process.env.GATSBY_PRISMIC_REPO,
  linkResolver: () => linkResolver,
})
